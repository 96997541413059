//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "Profile-4.vue",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Profile 2"
    }]);
  }
};